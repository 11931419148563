<template>
  <crud-form
    v-if="schema"
    :schema="schema"
    :model-id="id"
    :path="'product'"
    :form-title="$t('editProduct')"
    :redirect-route="'ProductList'"
  />
</template>

<script>
  import CrudForm from '../../components/CrudForm';
  import fieldProductTagItem from "./components/FieldProductTagItem";
  import fieldProductAttributeValue from "./components/FieldProductAttributeValue";
  import { productTagItemsValidator } from "./validations/productTagItems";
  import {APIService} from "../../services/api";
  import {schema as initialSchema} from "./schema/product";
  import {schema as middleSchema} from "./schema/productMiddle";
  import {schema as endSchema} from "./schema/productEnd";
  import Vue from "vue";

  Vue.component('fieldProductTagItem', fieldProductTagItem);
  Vue.component('fieldProductAttributeValue', fieldProductAttributeValue);

  export default {
    name: 'UpdateProduct',
    components: {
      CrudForm
    },
    props: ['id'],
    data: () => {
      return {
        schema: null,
      }
    },
    async beforeMount() {
      const tagItemsSchema = {
        fields: [
          {
            type: "productTagItem",
            model: 'tagItems',
            styleClasses: "dummy-error",
            required: true,
            validator: [
              productTagItemsValidator,
            ],
          }
        ]
      };

      let productAttributes = [];
      try {
        productAttributes = await APIService.get('product-attribute');
      } catch(e) {
        console.error(e);
      }

      const extraAttributesSchema = {
        fields: [
          {
            type: "productAttributeValue",
            model: 'attributeValues',
            required: true,
            attributes: productAttributes,
          }
        ]
      };

      const schema = Object.assign({}, initialSchema);
      schema.fields = schema.fields.concat(tagItemsSchema.fields);
      schema.fields = schema.fields.concat(middleSchema.fields);
      schema.fields = schema.fields.concat(extraAttributesSchema.fields);
      schema.fields = schema.fields.concat(endSchema.fields);

      const productTagItemsIndex = schema.fields.findIndex(schema => schema.model === 'tagPriceItems');
      const priceTagItemsSchema = schema.fields[productTagItemsIndex];
      const packing = (await APIService.get('tag?filter=token||eq||packings'))[0];
      priceTagItemsSchema.priceTag = packing;
      priceTagItemsSchema.default[0].tagItem.tag = packing;
      
      schema.fields[productTagItemsIndex] = priceTagItemsSchema;

      this.schema = schema;
    }
  }
</script>

<style lang="scss">
  @import "./styles/product.scss";
</style>
